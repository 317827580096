import { useState } from 'react'
import { faXmark, faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom'


export default function Nav(){
    const [open, setOpen] = useState(false)

    return (
        <>
            <h3 className="nav-header">&nbsp;<button className="icon" onClick={() => { setOpen(true) }}><FontAwesomeIcon icon={ faBars } /></button></h3>
            <nav className={open ? "open" : ""}>
                <h3>menu <button className="icon" onClick={() => { setOpen(false) }}><FontAwesomeIcon icon={ faXmark } /></button></h3>
                <div className="container">
                    <ul>
                        <li><Link to={'/'} onClick={() => { setOpen(false) }}>Home</Link></li>
                        <li><Link to={'/software'} onClick={() => { setOpen(false) }}>Software</Link></li>
                        <li><Link to={'/training'} onClick={() => { setOpen(false) }}>Training</Link></li>
                        <li><Link to={'/projects'} onClick={() => { setOpen(false) }}>Projects</Link></li>
                        <li><Link to={'/pricing'} onClick={() => { setOpen(false) }}>Pricing</Link></li>
                        <li><Link to={'/clients'} onClick={() => { setOpen(false) }}>Clients</Link></li>
                        <li><Link to={'/contact'} onClick={() => { setOpen(false) }}>Contact</Link></li>
                    </ul>
                </div>
            </nav>
        </>
    )
}