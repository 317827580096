import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import '../styles/error.scss'

export default function ErrorPage(){
    return (
        <>
            <Helmet>
                <title>Error - PatentInspiration</title>
            </Helmet>
            <div id="error-page">
                <div className='center'>
                    <h1>oops<small>!</small></h1>
                    <h2>We couldn't find what you're looking for</h2>
                    <Link to="/" className="button">Back to homepage</Link>
                </div>
            </div>
        </>
    )
}