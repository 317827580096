import { faCopyright } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet, Link } from 'react-router-dom'
import Nav from './components/nav'
import React from 'react';

export default function App() {
  return (
    <>
      <Nav />
      <header>
        <div className='container'>
          <div className='logo'>
            <img src='/logo.svg' alt='PatentInspiration logo' />
            <div className='name'>
              <h1>Patent Inspiration</h1>
              <h3>creative helicopter view</h3>
            </div>
          </div>
          <div className='buttons'>
            <Link className='request-demo' to="/contact">Request demo</Link>
            <a href="https://app.patentinspiration.com/search?l=true">Sign in</a>
          </div>
        </div>
      </header>
      <main>
        <div className='container'>
          <Outlet />
        </div>
      </main>
      <footer>
        <div className='container'>
          <h4>Security & Privacy</h4>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/cookie-policy">Cookie Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
          </ul>
        </div>
        <h3><FontAwesomeIcon icon={faCopyright} /> <span className='year'>{ new Date().getFullYear() }</span> aulive</h3>
      </footer>
    </>
  );
}
