import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function CookiesPage(){
    return (
        <>
            <Helmet>
                <title>Cookie Policy - PatentInspiration</title>
            </Helmet>
            <section className="cookie-policy">
                <h1>Cookie policy</h1>
                <p>This is the cookie policy of the website of AULIVE SOFTWARE NV, <Link to="/">www.patentinspiration.com</Link> (“AULIVE Software” or “we”). AULIVE Software is a public limited liability company incorporated under Belgian law, whose registered office is established at 8902 Ieper, Vijfwegenstraat 4 (VAT BE 0817.291.712).</p>
                <p>This cookie policy aims at informing you about the use of cookies by AULIVE Software. This policy should be read together with AULIVE Software’s privacy policy, which is available <Link to="/privacy-policy">here</Link>.</p>
                <p>The cookie policy can be modified from time to time. We thus advise you to check this cookie policy regularly. Any major changes will be announced via our homepage.</p>
                <p>This version of the cookie policy was last modified on 6 June 2018.</p>
                <h3>1. AULIVE SOFTWARE WEBSITE AND COOKIES</h3>
                <p>When you visit our website, we collect certain information by automated means, using cookies. We may also use other technologies such as pixel tags, browser analysis tools, server logs and web beacons for similar purposes as described in this policy and we may include them on our website, in marketing e-mail messages or our newsletter, etc., to determine whether messages have been opened and links clicked on. The information provided below about cookies also applies to these other technologies. The information we collect via cookies and other tools is used in a non-identifiable way without any reference to your personal information.</p>
                <h3>2. WHAT ARE COOKIES?</h3>
                <p>Cookies are small text files that are installed on your browser, on your computer or on your mobile device. They allow a website to recognise your device. Cookies can have several purposes: to ensure that the website takes into account the user’s preferences by for example remembering language preferences, to collect statistical data, to adapt the content etc.</p>
                <p>We use cookies for different reasons: for the technical and functional management of the website, to personalise your website visit, to give us commercial and marketing information and to enable users to share content.</p>
                <p>
                    We use both functional, performance and navigational cookies:
                    </p><ul>
                        <li><b>Functional cookies</b>: cookies that improve the convenience of your website visit and improve the user experience.</li>
                        <li><b>Performance cookies</b>: cookies that gather information to evaluate and improve the content of our website (e.g.: analytics).</li>
                        <li><b>Navigational cookies</b>: cookies that collect information about your computer and your visits to this website such as your IP address, geographical location, browser type, referral source, length of visit and pages viewed.</li>
                    </ul>
                    Below you will find a summary of the cookies that AULIVE Software uses on its website:
                    <table id="cookie-table">
                        <tbody><tr>
                            <th>Name</th>
                            <th>Information</th>
                            <th>Expiration Period</th>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>Google Analytics: used to distinguish users</td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>Google Analytics: Used to distinguish users</td>
                            <td>24 hours</td>
                        </tr>
                        <tr>
                            <td>_gat</td>
                            <td>Google Analytics: Used to throttle request rate</td>
                            <td>1 minute</td>
                        </tr>
                        <tr>
                            <td>_zlcmid</td>
                            <td>Used by Zopim (Live Chat)</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>cls</td>
                            <td>PatentInspiration: Used to store the legend size of the codemap</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>dls</td>
                            <td>PatentInspiration: Used to store the legend size for dynamic legends</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>highlight</td>
                            <td>PatentInspiration: Used to store whether highlighting is enabled or not</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>patentApplicants</td>
                            <td>PatentInspiration: Used to store the type of applicant in the patent detail view</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>patentInventors</td>
                            <td>PatentInspiration: Used to store the type of inventor in the patent detail view</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>patentTimeline</td>
                            <td>PatentInspiration: Used to store the type of timeline in the patent detail view</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>showHighlight</td>
                            <td>PatentInspiration: Used to store if the highlight panel is visible or not</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>filter-facet-how</td>
                            <td>PatentInspiration: Used to store the user&apos;s preference for the visual filter assistant</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>filter-facet-when</td>
                            <td>PatentInspiration: Used to store the user&apos;s preference for the visual filter assistant</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>filter-facet-where</td>
                            <td>PatentInspiration: Used to store the user&apos;s preference for the visual filter assistant</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>filter-facet-who</td>
                            <td>PatentInspiration: Used to store the user&apos;s preference for the visual filter assistant</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>pi_cp</td>
                            <td>PatentInspiration: Used to store if the user aknowledged the cookie policy notification</td>
                            <td>1 year</td>
                        </tr>
                    </tbody></table>
                    Some cookies we use are from third parties, such as Google, Zendesk (Zopim) to provide us with web analytics and intelligence about our website. These companies use programming codes to collect information about your interaction with our website, such as the pages you visit, the links you click on and how long you are on our website. For more information on how these companies collect and use your information, please refer to their privacy and cookie policies.
                <p></p>
                <h3>3. HOW CAN YOU DELETE OR BLOCK OUR COOKIES OR CHANGE YOUR COOKIE SETTINGS?</h3>
                <p>Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue cookies as soon as you visit our website. By using our website, you agree that we can place cookies on your device.</p>
                <p>At any time, users of our website can delete the already installed cookies on their computer or mobile device as well adjust their internet browser’s cookie settings. These settings are usually found in the &apos;options&apos; or &apos;preferences&apos; menu of your internet browser. You can use the &apos;Help&apos; option in your internet browser for more details.</p>
                <p>However, remember that, if you turn off cookies, certain parts of our website and some graphic elements may not be accessible or not look as good and you may not be able to use certain applications.</p>
                <h3>4. QUESTIONS?</h3>
                <p>If you have further questions or complaints about this website or about this cookie policy, you can also contact <a href="mailto:support@patentinspiration.com">support@patentinspiration.com</a>.</p>
            </section>
        </>
    )
}