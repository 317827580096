import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';

export default function CookiesPage(){
    return (
        <>
            <Helmet>
                <title>Training - PatentInspiration</title>
            </Helmet>
            <section className="training">
                <div className='banner'>
                    <img src='/images/banner2.png' alt='Training' />
                    <p>Below are tips and tricks on how to use Patent Inspiration. Don’t be overwhelmed by the amount of possibilities and visualizations, every challenge will require its specific set of tools. Good luck.</p>
                    <Link className="button" to="/contact">course<br/>training</Link>
                </div>
                <article>
                    <h1>How to start</h1>
                    <div className="img-container"><img src='/images/training1.png?v=2' alt='Solutions' /></div>
                    <p>Every project starts with your data filter. Filters allow for semantic searching of the patent data, different filters can be combined using AND, OR and NOT operators. Patent Inspiration also provides a unique thesaurus called &apos;Find related terms&apos; in which you can enrich your filter with relevant keywords. You are now ready to start analyzing.
                    <a href="https://assets.patentinspiration.com/cheatsheet.pdf" className="resource-link" target="_blank" rel="noreferrer">cheat sheet</a>
                    <a href="https://app.patentinspiration.com/features/relatedterms" className="resource-link" target="_blank" rel="noreferrer">find related terms</a>
                    </p>
                </article>
                <article>
                    <h1>Solve a problem</h1>
                    <div className="img-container"><img src='/images/training2.png?v=2' alt='Solve a problem' /></div>
                    <p>The key to problem solving with outside knowledge is abstraction. Can you abstract your challenge into a format that removes the context. For example, if your challenge is that oil is separating in your cosmetic cream, you can abstract the search into &apos;prevent oil separation&apos;. Within this solution pool peanut butter appears. By applying peanut butter stabilizers, your cream problem can be solved. Tip: use <b>Noun in Title</b> to visualize the solution areas.</p>
                </article>
                <article>
                    <h1>Find a new market</h1>
                    <div className="img-container"><img src='/images/training3.png?v=2' alt='Find a new market' /></div>
                    <p>You are a producer of SiOx barrier coating packaging material for the food industry.  Build a pool of patents that contain &quot;SiOx barrier&quot; and mine the data by configuring a new <b>Text Pattern</b> analysis: <br/><br/><b>adjective:* packaging</b><br/>(giving you the properties e.g. aseptic)<br/><br/><b>noun:* packaging</b><br/>(giving you the context e.g. paper).<br/><br/>The results will allow you to explore new markets.</p>
                </article>
            </section>
        </>
    )
}