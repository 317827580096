import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function QuestelPage(){
    return (
        <>
            <Helmet>
                <title>Questel Data Partnership - PatentInspiration</title>
            </Helmet>
            <section className="questel-data">
                <img className="banner" alt="Questel data banner" src="/images/questel.png" />
                <h1>Top tool meets top data<small>Ieper, December 1st 2022</small></h1>
                <div className="grid">
                    <div className="block">
                        <h2>A strategic data partnership</h2>
                        <p>We are proud to announce a strategic data  partnership with Questel &#8208; Orbit, France. From early 2023, Patent Inspiration is integrating all full text, legal and patent data from Questel Orbit, bringing its data quality amongst the highest in the global market.</p>
                    </div>
                    <div className="block double-row">
                        <h2>Boosting our text analysis and AI</h2>
                        <p>The partnership will be completing full text data of the often more challenging  countries. This will have a major impact on the text analysis tools uniquely provided in Patent Inspiration. The product or process properties patterns will bring higher trend analysis accuracy.</p>
                        <p>The data increase will furthermore feed the AI driven capabilities raising them to even higher accuracy. Patent Inspirations unique thesaurus (Find Related Terms), its proprietary algorithm for identifying similar patents and the very popular competitor identifier all rely on AI and data quantity; more data, more accuracy.</p>
                    </div>
                    <div className="block">
                        <h2>Unseen power</h2>
                        <p>With Patent Inspiration&apos;s unique text analysis, innovation tools and AI capabilities, the total platform is upgrading to a level of unseen power in the patent research offering today. Questel data guarantees  you access to the largest accurate patent database.</p>
                    </div>
                    <div className="block triple-row">
                        <h2>More and different data</h2>
                        <p>The integration of legal data in Patent Inspiration will bring new visualizations and analysis related to legal status, licensing, collaborations etc. which can be interpreted in innovation research  for both problem solving and market identification.</p>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} /> 125 million publications</li>
                            <li><FontAwesomeIcon icon={faCheck} /> 110 patent-issueing authorities</li>
                            <li><FontAwesomeIcon icon={faCheck} /> 96 million patents</li>
                            <li><FontAwesomeIcon icon={faCheck} /> 66 million patent families</li>
                            <li><FontAwesomeIcon icon={faCheck} /> Patent classification codes</li>
                            <li><FontAwesomeIcon icon={faCheck} /> Proprietary normalized technology domains & sub-domains</li>
                            <li><FontAwesomeIcon icon={faCheck} /> Patent and non-patent citation reports with relevancy indicators</li>
                            <li><FontAwesomeIcon icon={faCheck} /> 110 patent-issueing authorities</li>
                            <li><FontAwesomeIcon icon={faCheck} /> High resolution patent drawing mosaics</li>
                        </ul>
                    </div>
                    <div className="block">
                        <blockquote><FontAwesomeIcon icon={faQuoteLeft} /> Our new data delivers you access to the largest accurate patent database</blockquote>
                    </div>
                    <div className="block">
                        <h2>From flaw to forte</h2>
                        <p>Awarded INSEAD Innovator of the year 2010 and Gartner Cool Vendor 2017, Patent Inspiration defends its unique position from an innovation research angle into patent data. The translation of Innovation Logic into analysis and visualizations were prioritized  over the full data availability.  Questel Orbit data is turning this minor flaw into a major forte for Patent Inspiration, where the top tool meets top data.</p>
                    </div>
                    <div className="block">
                        <h2>Enthusiasm</h2>
                        <p>Grateful to our <Link to="/clients">members</Link> of Patent Inspiration, most have been with us for years, we are humbly enthusiastic to the vast amount of opportunities this data partnership will bring to the world of Innovation Logic with Patent Inspiration. Thank you.</p>
                    </div>
                </div>
            </section>
        </>
    )
}