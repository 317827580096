import React from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function HomePage(){
    return (
        <>
            <Helmet>
                <title>Search and analyze patents - PatentInspiration</title>
            </Helmet>
            <section className="home">
                <article className='wide-text'>
                    <div className="img-container"><img src='/images/home1.png?v=2' alt='Clients' style={{ maxHeight: "200px" }} /></div>
                    <h1>Who runs Patent Inspiration?</h1>
                    <p>Technology driven companies, research institutes, universities, IP lawyers and innovation consultants take the step to Patent Inspiration for its unique capabilities.<Link className="read-more" to="/clients">read more</Link></p>
                </article>
                <article>
                    <h1>Helicopter view on technology</h1>
                    <div className="img-container"><img src='/images/home2.png?v=2' alt='Helicopter view' /></div>
                    <p>Patent Inspiration enables you to slice and dice the data into an abundant set of visualizations. The dashboard acts as a helicopter view on patent literature, allowing you to land at any time on the specifics. Our software is so different that the larger part of our toolbox is simply not present in other tools.<Link className="read-more" to="/software">read more</Link></p>
                </article>
                <article className="small-image">
                    <h1 className="fold">Top tool meets top data<small>Ieper, December 1st 2022</small></h1>
                    <div className="img-container"><img src='/images/home3.png?v=2' alt='Data' /></div>
                    <p>We are proud to announce a strategic data partnership with Questel &#8208; Orbit, France. From January 2023, Patent Inspiration is integrating all full text, legal and patent data from Questel Orbit, bringing its data quality amongst the highest in the global market. With Patent Inspiration&apos;s unique text analysis, innovation tools and AI capabilities, the total platform is upgrading to a level of unseen power in the patent research offering today.<Link className='read-more' to="/articles/questel">read more</Link></p>
                </article>
                <article>
                    <h1>Become an expert in Innovation Logic<sup>TM</sup></h1>
                    <div className="img-container"><img src='/images/home4.png?v=2' alt='Innovation Logic' /></div>
                    <p>Whether it is to identify technology for solving your problem, or finding new markets for your technology. Open Innovation Mapping is Patent Inspiration at its fullest. It answers the question: Who outside my domain can help me in my challenge? Or in the case of finding new markets: Who can use my technology outside my existing markets?<Link className="read-more" to="/training">read more</Link></p>
                </article>
                <article>
                    <h1>Countless successfull projects</h1>
                    <div className="img-container"><img src='/images/home5.png?v=2' alt='Projects' /></div>
                    <p>Patent Inspiration has been used as the main software tool in several hundreds of innovation projects over the years.You may choose to conduct a project before committing to the software. We offer problem solving, innovation and market identifying studies to validate the power and efficiency of Patent Inspiration. Contact us for projects on your current challenge.<Link className="read-more" to="/projects">read more</Link></p>
                </article>
            </section>
        </>
    )
}