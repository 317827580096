import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';

export default function ClientsPage(){
    return (
        <>
            <Helmet>
                <title>Clients - PatentInspiration</title>
            </Helmet>
            <section className="clients">
                <article>
                    <h1>Boost your research</h1>
                    <div className="img-container"><img src='/images/clients1.png?v=3' alt='Boost your research' /></div>
                    <p>Access to solutions, Patent Inspiration gives researchers access to millions of solution documents. The unique advanced text analysis and visualization tools make PatentInspiration very attractive for patent lawyers. When solution looking for problems, abstracting the solution Patent Inspiration can quickly identify where you can apply or transfer the technology. Current Patent Inspiration users include:</p>
                </article>
                <article>
                    <h1>Reinvent your products</h1>
                    <div className="img-container"><img src='/images/clients2.png?v=2' alt='Reinvent your products' /></div>
                    <p>Innovating in a rapidly changing consumer market, Patent inspiration watches  competitor moves, scouts for relevant new technologies for your products and production processes. With increasing ingredient prices and packaging trending to minimalistic, personalized and biodegradable, the easy access to Patent Inspiration brings refreshing ideas for your innovation projects.</p>
                </article>
                <article>
                    <h1>Transform your industry</h1>
                    <div className="img-container"><img src='/images/clients3.png?v=2' alt='Transform your industry' /></div>
                    <p>Patent Inspiration contains abundant solutions on the ever increasing environmental restrictions , waste management, energy recovery,  etc. faced by industry. With a continuous focus on cost and reliability patents can provide strong insights into working solutions for optimizing the manufacturing process. By accessing existing research one can save research energy.</p>
                </article>
            </section>
        </>
        
    )
}