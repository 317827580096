import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function CookiesPage(){
    return (
        <>
            <Helmet>
                <title>Software - PatentInspiration</title>
            </Helmet>
            <section className="software">
                <article>
                    <h1>Superior text analysis and AI</h1>
                    <div className="img-container"><img src='/images/software1.png?v=2' alt='Text analysis' /></div>
                    <p>Finding related search terms is offered in a popular thesaurus system built on patent context data. An easy interface for semantic searching gives total freedom to query data. Patent inspiration offers a proprietary &apos;similar patent&apos; search. One more application of AI is to identify competitors. Competitors of my clients can be new clients, competitors of my suppliers can be cheaper or better suppliers.</p>
                </article>
                <article>
                    <h1>Unique tools for innovation</h1>
                    <div className="img-container"><img src='/images/software2.png?v=2' alt='Tools for innovation' /></div>
                    <p>An array of innovation tools woven into an innovation logic process support problem solving, technology &#8208; market studies and product or process innovation. Convert adjectives into properties and functions into verbs and your trend analysis has started whilst you can identify white spaces. The approach is simple whilst uncovering powerful insights.</p>
                </article>
                <article>
                    <h1>Exclusive interactive visualizations</h1>
                    <div className="img-container"><img src='/images/software3.png?v=2' alt='Interactive visualizations' /></div>
                    <p>Mapping materials, elements, fields, technologies, acronyms, modifiers, domains, values, competitors and much more gives you a leading capability in patent data driven innovation logic. It is by far the quickest access to knowledge inside and outside your domain.</p>
                </article>
            </section>
        </>
    )
}