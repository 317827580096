import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function CookiesPage(){
    return (
        <>
            <Helmet>
                <title>Privacy Policy - PatentInspiration</title>
            </Helmet>
            <section className="privacy-policy">
                <h1>Privacy policy</h1>
                <p>This is the privacy policy of the website and application available at <Link to="/">www.patentinspiration.com</Link> (hereafter the “Application”) of AULIVE SOFTWARE NV, (“AULIVE Software” or “we”). AULIVE Software is a public limited liability company incorporated under Belgian law, whose registered office is established at 8902 Ieper, Vijfwegenstraat 4 (VAT BE 0817.291.712).</p>
                <p>This privacy policy is intended to inform you about the personal data that AULIVE Software collects through its Application to explain why we collect this data and to explain how we deal with your data.</p>
                <p>The privacy policy can be modified, e.g. as a result of new functionalities of the Application that requires the processing of personal data. Any major changes will be announced upon login.</p>
                <p>Present version of the privacy policy was last modified on 6 June 2018.</p>
                <h3>1. WHEN DOES THIS PRIVACY POLICY APPLY?</h3>
                <p>This privacy policy applies to the processing of personal data through the Application and the website of AULIVE Software, <Link to="/">www.patentinspiration.com</Link></p>
                <h3>2. WHO IS RESPONSIBLE FOR THE PROCESSING OF YOUR PERSONAL DATA?</h3>
                <p>AULIVE Software is the party responsible for the processing of your personal data. This means that AULIVE Software determines for what purposes your personal data will be processed and which means will be used to process your personal data.</p>
                <h3>3. WHICH PERSONAL DATA DO WE PROCESS ABOUT YOU?</h3>
                <p>We only collect your personal data in two ways.</p>
                <h4>3.1	INFORMATION PROVIDED BY YOU</h4>
                <p>
                    We receive personal data when you share it with us in the Application:
                    </p><ul>
                        <li>When you register on the Application</li>
                        <li>When you perform searches through keywords in the Application</li>
                        <li>When you fill out a form on our website</li>
                        <li>If you request information about our Application</li>
                        <li>If you get in touch with us via e-mail or via Skype</li>
                        <li>etc</li>
                    </ul>
                <p></p>
                <p>When you purchase a license to the Application we receive information such as your name, e-mail address, company name, address, country and payment details.</p>
                <h4>3.2	AUTOMATICALLY OBTAINED INFORMATION</h4>
                <p>When you browse our website, we receive data from cookie providers about your activity on our website such as the time and duration of your visit, the webpages you visited before and after using our website, your IP address, the pages you visit, the browser you use, geographical data such as your location.</p>
                <p>We do this by using cookies. More information on the use of cookies by AULIVE Software can be found in our <Link to="/cookie-policy">cookie policy</Link>.</p>
                <h3>4. WHAT IS THE LEGAL GROUND FOR PROCESSING YOUR PERSONAL DATA?</h3>
                <p>
                    AULIVE Software processes your personal data for various purposes, which are further explained below. Regarding the processing of your personal data, AULIVE Software always relies on:
                    </p><ul>
                        <li>The consent that you expressed, e.g. by ticking a box to this effect during your navigation on our website, or via another positive action from which your consent is inferred, e.g. by choosing to contact us via the contact form. In this case, you have the right to withdraw your consent for the future at all time.</li>
                        <li>Another legal basis laid down by law: for example, if the collection of data is necessary for the performance of a contract with you (e.g. the license to use the Application) or if processing is necessary for the fulfilment of a legal obligation to which AULIVE Software is subject (e.g. invoicing).</li>
                        <li>In some cases, the processing of your data may also be necessary in relation to a legitimate interest of AULIVE Software.</li>
                    </ul>
                <p></p>
                <h3>5. FOR WHICH PURPOSES DO WE PROCESS YOUR PERSONAL DATA?</h3>
                <h4>5.1	IN ORDER TO RESPOND TO YOUR REQUESTS AND TO KEEP YOU INFORMED</h4>
                <p>We may contact you for direct marketing purposes. This includes sending you information we think you might like or marketing communications relating to our business by mail, e-mail or other means, sending you marketing communications relating to our business or contacting your for market research purposes.</p>
                <p>For this, we process your name and e-mail address.</p>
                <p>Depending on the individual case, these data are processed on the basis of your unambiguous consent, on the basis of the performance of a contract to which you are a party, or on the basis of your request to take steps prior to entering into a contract.</p>
                <p>In every e-mail we send you, we give you the opportunity to unsubscribe, but you can also contact us at any time for more information.</p>
                <h4>5.2	IN ORDER TO PROVIDE OUR PRODUCTS AND SERVICES TO YOU AND TO IMPROVE OUR PRODUCTS OR SERVICES BASED ON YOUR NEEDS</h4>
                <p>If you have subscribed to the Application, we will process your personal data for the good functioning and use of the Application. In addition, we process your personal data based on your consent to improve our products or services. For this purpose, we can process data such as your name, your e-mail address, keywords(searches), reports, briefcases and user statistics.</p>
                <p>Depending on the individual case, these data are processed based on the performance of a contract to which you are a party.</p>
                <h4>5.3	TO ANSWER YOUR QUESTIONS</h4>
                <p>If you get in touch with us via the contact form on our website, via e-mail or via Skype, we will use your personal data in order to reply and answer your question.</p>
                <p>For this purpose, we process your name, e-mail address, telephone number and country. Furthermore, we process your correspondence with AULIVE Software about your question and all other personal data you provide to us and which are necessary to answer your question.</p>
                <p>Depending on the individual case, these data are processed on the basis of your request to take steps prior to entering a contract, on the basis of the performance of a contract to which you are a party, or on the basis of AULIVE Software’s legitimate interest in informing you about its activities.</p>
                <h4>5.4	FOR THE TECHNICAL AND FUNCTIONAL MANAGEMENT AND ANALYSIS OF THE APPLICATION</h4>
                <p>We process personal data for the technical and functional management of our Application, in order to personalise the Application and to ensure that the Application is easy to use. </p>
                <p>For this purpose we can process data such as your IP address, the browser you use, geographical data such as your location, your preferred language and the time and duration of your visit. For the website, we do this e.g. by using cookies (more information to be found in our <Link to="/cookie-policy">cookie policy</Link>.</p>
                <p>Depending on the individual case, these data are processed on the basis of AULIVE Software’s legitimate interest in improving its Application and protecting it against cyber-attacks.</p>
                <h4>5.5	FOR BILLING PURPOSES</h4>
                <p>We process your personal data for billing purposes.</p>
                <p>For this purpose, we can process data such as your name, e-mail address, address, and your payment details.</p>
                <p>These data are processed based on the performance of a contract to which you are a party or is necessary for the fulfilment of a legal obligation to which AULIVE Software is subject.</p>
                <h4>5.6	FOR IDENTIFICATION AND AUTHENTICATION</h4>
                <p>We process your personal data in order for us to identify and authenticate you when logging in into our website.</p>
                <p>For this purpose, we can process data such as your e-mail address.</p>
                <p>These data are processed based on the performance of a contract to which you are a party.</p>
                <h3>6. WHO HAS ACCESS TO YOUR PERSONAL DATA?</h3>
                <p>AULIVE Software is selective in the parties to whom it discloses your personal data and chooses them carefully. In all cases, we will ensure that sufficient safeguards for the protection and confidentiality of your data are in place.</p>
                <p>AULIVE Software uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run our services. Personal data are not provided to other parties, unless this should be required by law or a court order.</p>
                <p>If these parties have access to personal data for the services they provide, AULIVE Software has taken the necessary contractual and organisational measures to ensure that the data are only processed insofar as necessary to perform the services.</p>
                <p>
                    In the following cases, we may share your personal data with third parties:
                    </p><ul>
                        <li>IT service providers that provide hosting services, software or other technical support</li>
                        <li>Certain processors who act on our behalf, such as e.g. payment service providers and marketing companies.</li>
                        <li>Attorneys and external advisors: in certain cases AULIVE Software may transfer your personal data to attorneys and external advisors if this is necessary to give us advice or to defend our rights.</li>
                        <li>Supervisory authorities in case of a lawful request.</li>
                        <li>Possible acquirers of AULIVE Software in the unlikely event that (a part of) our activities would be taken over by a third party.</li>
                    </ul>
                <p></p>
                <h3>7. DOES AULIVE SOFTWARE PROCESS YOUR DATA OUTSIDE THE EUROPEAN ECONOMIC AREA (EEA)?</h3>
                <p>In some cases, your personal data may be shared with a third party outside the EEA for the purposes as described in point 5 above. In this respect, AULIVE SOFTWARE is committed to always ensuring an adequate and sufficient level of protection for your data, in particular by making sure that we can rely on a an adequacy decision under article 45 GDPR, by concluding standard contractual clauses under article 46.2 GDPR or via any other appropriate safeguard.</p>
                <p>If you have any questions or would like more information about the transfer of your personal data outside the EEA, you can send a dated and signed request by e-mail to <a href="mailto:support@patentinspiration.com">support@patentinspiration.com</a> or by regular mail to the address as specified in section 12 of this privacy policy</p>
                <h3>8. WHAT ABOUT LINKS TO OTHER WEBSITES AND SOCIAL MEDIA?</h3>
                <p>The website of AULIVE Software can contain references (e.g. via hyperlinks) to other websites, which are offered by other companies or by social media. AULIVE Software is not responsible for the processing of personal data via these websites. Nor does this privacy policy apply to these websites. AULIVE Software advises you to check the privacy policies of these social media. </p>
                <p>If you share content from the AULIVE Software website via social media, your personal data will be visible for the visitors of these social media. AULIVE Software is not responsible for the processing of personal data via these social media, nor does this privacy policy apply to these social media.</p>
                <h3>9. HOW DO WE PROTECT YOUR PERSONAL DATA?</h3>
                <p>AULIVE Software strives to protect your personal data as well as possible by means of technical and organisational measures, such as firewalls and secured servers that may only be accessed by a limited number of persons holding special access rights and who undertake to respect and maintain its confidential nature.</p>
                <h3>10. HOW LONG DO WE KEEP YOUR PERSONAL DATA?</h3>
                <p>AULIVE Software processes your personal data as long as you have an account to use the Application. If you cancel or terminate your account, AULIVE Software will retain your personal data for a period of one year. This allows us to restore your data in case you decide to reactivate your account within the following year. After the retention period, all your personal data will be permanently deleted from our servers.</p>
                <p>If you do not have an account to use our Application, AULIVE Software will retain your personal data for no longer than necessary to fulfil the purposes outlined in this privacy policy.</p>
                <p>11. WHAT ARE YOUR RIGHTS AND HOW CAN YOU EXERCISE THEM?</p>
                <p>Under the GDPR, you have a number of rights with regard to your personal data. Some of these rights have a very specific scope or are subject to special conditions or exceptions. The scope of your rights is explained below.</p>
                <p>You can send the requests to exercise your rights by e-mail to support@patentinspiration.com or by regular mail to the address as specified in section 12 of this privacy policy. Please note that AULIVE Software cannot process your request without proof of your identity.</p>
                <h4>11.1 RIGHT OF ACCESS (ARTICLE 15 GDPR)</h4>
                <p>This means e.g. that you can ask us whether or not we process your personal data and for what purposes we do this.</p>
                <h4>11.2 RIGHT TO RECTIFICATION (ARTICLE 16 GDPR)</h4>
                <p>You also have a right to obtain the rectification of inaccurate personal data.</p>
                <h4>11.3 RIGHT TO ERASURE (ARTICLE 27 GDPR)</h4>
                <p>In some cases, we are obliged to erase your personal data, e.g. when personal data is no longer required for the purposes for which we have collected it or when the personal data have been unlawfully processed.</p>
                <h4>11.4 RIGHT TO RESTRICTION OF PROCESSING (ARTICLE 18 GDPR)</h4>
                <p>You may request a restriction of your personal data, e.g. if you dispute the accuracy of your personal data. A restriction of the processing means that we may only store your data temporarily, except in case you nevertheless consent to the processing of your data, in the context of legal proceedings or in the public interest.</p>
                <h4>11.5 RIGHT TO DATA PORTABILITY (ARTICLE 20 GDPR)</h4>
                <p>In certain cases, you can ask us to transfer your data to yourself or to another controller (if this is technically possible).</p>
                <h4>11.6 RIGHT TO OBJECT (ARTICLE 21 GDPR)</h4>
                <p>In general: If the processing of your personal data is based on AULIVE Software’s legitimate interest, you can inform us of the particular reasons why you wish to object to the particular processing activity. If these reasons outweigh our legitimate interests, we will stop the processing activity.</p>
                <p>In the context of direct marketing: If we process your personal data for direct marketing purposes, you can always ask us to stop processing your personal data without you having to provide a special reason.</p>
                <h4>11.7 RIGHT NOT TO BE SUBJECT TO PROFILING (ARTICLE 22 GDPR)</h4>
                <p>AULIVE Software will not process your personal data for profiling purposes.</p>
                <h4>11.8 RIGHT TO WITHDRAW YOUR CONSENT AT ANY TIME</h4>
                <p>If the processing of your data is based on your consent, you also have the right to withdraw your consent at any time, without this affecting the legitimacy of the processing done prior to the withdrawal of your consent.</p>
                <h4>11.9 RIGHT TO LODGE A COMPLAINT (ARTICLE 77 GDPR)</h4>
                <p>In accordance with article 77 of the General Data Protection Regulation (GDPR), you have the right to lodge a complaint with the supervisory authority of your habitual residence, your place of work or the place of an alleged infringement of the GDPR. The Belgian Data Protection Authority is the supervisory authority for Belgium and can be contacted at <a href="mailto:commission@privacycommission.be">commission@privacycommission.be</a>.</p>
                <h3>12	WHO CAN YOU CONTACT IN ORDER TO EXERCISE YOUR RIGHTS?</h3>
                <p>
                    If you have any further questions about the processing of your personal data, about AULIVE Software’s website or about this privacy policy, you can always contact us:
                    </p><ul>
                        <li>Via the <Link to="/contact">contact form</Link> available on our website</li>
                        <li>By e-mail via <a href="mailto:support@patentinspiration.com">support@patentinspiration.com</a></li>
                        <li>By mail to: AULIVE Software NV, Vijfwegenstraat 4, 8902 Ieper, Belgium</li>
                    </ul>
                <p></p>
            </section>
        </>
    )
}