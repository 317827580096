import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function CookiesPage(){
    const [users, setUsers] = useState(1)

    return (
        <>
            <Helmet>
                <title>Pricing - PatentInspiration</title>
            </Helmet>
            <section className="pricing">
                <article>
                    <h1>Your software, get more for less</h1>
                    <div className="pricing-calculator">
                        <b>Calculate your price based on your needs:</b>
                        <input type="number" min="1" value={ users } onChange={(e) => { setUsers(parseInt(e.target.value)) }}/> users
                        <div className='pricing-result'>
                            {
                                users < 26 ? <><span>&euro;{ (170 + (users * 30)) } / month</span><span>&euro;{ (1870 + (users * 330)) } / year</span></> : <span>For large groups, please contact us for a discount.</span>
                            }
                        </div>
                    </div>
                    <div className="img-container"><img src='/images/pricing1.png?v=2' alt='Pricing' /></div>
                    <p>
                        <h4>Discounts:</h4>
                        <ul>
                            <li>- Order one year and get a month off</li>
                            <li>- Academics receive a 50% discount</li>
                            <li>- Medium teams (25+) receive 20%</li>
                        </ul>
                        <h4>Flexible:</h4>
                        Join for a month, a year, one person or many. All memberships are fully adaptable over time. Our tool/pricing offer is the most competitive in the market.
                        <br/><a className="button" href="https://app.patentinspiration.com/signup">Subscribe now</a>
                        </p>
                </article>
                <article>
                    <h1>Your custom training</h1>
                    <div className="img-container"><img src='/images/pricing2.png?v=2' alt='Training' /></div>
                    <p>
                    Patent Inspiration is both a method and a tool The process is Innovation Logic, an award winning technique that makes innovation a clear-cut process. From personal to larger groups we offer a tailored training program that fits with your availability and needs.
                    <br/><Link className="button" to="/contact">Request your training</Link>
                    </p>
                </article>
                <article className="small-image">
                    <h1>Projects to get you going</h1>
                    <div className="img-container"><img src='/images/pricing3.png?v=2' alt='Projects' /></div>
                    <p>
                    As described in <Link to="/projects">projects</Link>, we offer three types of studies: problem solving, innovation and market identification.<br/><br/>The studies are typically categorized in:
                    <ul>
                        <li>- Mini 3-5 days</li>
                        <li>- Medium 10-15 days</li>
                        <li>- Full 20-30 days</li>
                    </ul>
                    <br/>
                    We conduct all projects in high interaction and total transparency.
                    <br/><Link className="button" to="/contact">Request your project</Link>
                    </p>
                </article>
            </section>
        </>
    )
}