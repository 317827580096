import React from 'react';
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

export default function ContactPage(){
    const [showSuccessNotification, setSuccessNotification] = useState(false)
    const [showErrorNotification, setErrorNotification] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async(event:any) => {
      event.preventDefault()

      if (submitting)
        return

      setSubmitting(true);

      dayjs.extend(timezone)

      const data = {
        firstName: event.target.firstName.value,
        lastName: event.target.lastName.value,
        email: event.target.email.value,
        message: event.target.message.value,
        company: event.target.company.value,
        timezone: dayjs.tz.guess()
      }

      const JSONdata = JSON.stringify(data)

      const endpoint = process.env.NODE_ENV === "development" ? "https://localhost:56136/contact" : "https://api.patentinspiration.com/contact"

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSONdata,
      }

      // Send the form data to our API and get a response.
      try {
        await fetch(endpoint, options)
        setSuccessNotification(true)
        setSubmitting(false)
        //clear fields
        event.target.firstName.value = "";
        event.target.lastName.value = "";
        event.target.email.value = "";
        event.target.company.value = "";
        event.target.message.value = "";
        setTimeout(() => { setSuccessNotification(false) }, 5000)
      } catch (error) {
        setErrorNotification(true)
        setSubmitting(false)
        setTimeout(() => { setErrorNotification(false) }, 5000)
      }
    }

    return (
      <>
        <Helmet>
          <title>Contact - PatentInspiration</title>
        </Helmet>
        <section className="contact">
          <h1>Please use the form below to get in touch:</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="firstName">First Name:</label>
            <input type="text" id="firstName" name="firstName" placeholder="First name *" required />
            <label htmlFor="lastName">Last Name:</label>
            <input type="text" id="lastName" name="lastName" placeholder="Last name *" required />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" placeholder="Email address *" required />
            <label htmlFor="company">Company:</label>
            <input type="text" id="company" name="company" placeholder="Company" />
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" placeholder="Your message *" required />
            <button type="submit">{ submitting ? "Submitting..." : "Submit" }</button>
          </form>
          <div className={"successNotification" + (showSuccessNotification ? " show" : "")}>
            <div className="content">
              <h1>Message sent</h1>
              <p>Your message has been sent successfully.</p>
            </div>
          </div>
          <div className={"errorNotification" + (showErrorNotification ? " show" : "")}>
            <div className="content">
              <h1>Error</h1>
              <p>Something has gone wrong, please try again later.</p>
            </div>
          </div>
        </section>
      </>
      )
}