import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import './styles/index.scss'
import reportWebVitals from './reportWebVitals';
import HomePage from './pages/Home'
import ClientsPage from './pages/Clients';
import ContactPage from './pages/Contact';
import CookiesPage from './pages/Cookies';
import PricingPage from './pages/Pricing';
import PrivacyPage from './pages/Privacy';
import ProjectsPage from './pages/Projects';
import SoftwarePage from './pages/Software';
import TermsPage from './pages/Terms';
import TrainingPage from './pages/Training';
import ErrorPage from './pages/ErrorPage';
import RedirectPage from './pages/Redirect';
import QuestelPage from './pages/articles/Questel';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "",
        element: <HomePage />
      },
      {
        path: "clients",
        element: <ClientsPage />
      },
      {
        path: "contact",
        element: <ContactPage />
      },
      {
        path: "cookie-policy",
        element: <CookiesPage />
      },
      {
        path: "cookies",
        element: <CookiesPage />
      },
      {
        path: "pricing",
        element: <PricingPage />
      },
      {
        path: "privacy-policy",
        element: <PrivacyPage />
      },
      {
        path: "privacy",
        element: <PrivacyPage />
      },
      {
        path: "projects",
        element: <ProjectsPage />
      },
      {
        path: "software",
        element: <SoftwarePage />
      },
      {
        path: "terms-of-service",
        element: <TermsPage />
      },
      {
        path: "terms",
        element: <TermsPage />
      },
      {
        path: "training",
        element: <TrainingPage />
      },
      {
        path: "redirect",
        element: <RedirectPage />
      },
      {
        path: "articles/questel",
        element: <QuestelPage />
      }
    ]
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
