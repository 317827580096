import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

export default function RedirectPage(){
    const { search } = useLocation();

    return (
        <>
            <Helmet>
                <meta httpEquiv="refresh" content={"0; url = https://app.patentinspiration.com/#" + search.substring(5)}/>
            </Helmet>
            Redirecting...
        </>
    )
}