import React from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';

export default function CookiesPage(){
    return (
        <>
            <Helmet>
                <title>Projects - PatentInspiration</title>
            </Helmet>
            <section className="projects">
                <div className='banner'>
                    <img src='/images/banner3.png' alt='Projects' />
                    <p>The goal of Patent Inspiration is to bring a capability in your company. However, coaching trajectories or small projects will act as working case studies to boost the use of Patent Inspiration in your team.</p>
                    <Link className="button" to="/contact">project<br/>offer</Link>
                </div>
                <article>
                    <h1>Solutions to your problem</h1>
                    <div className="img-container"><img src='/images/projects1.png?v=3' alt='Clients' /></div>
                    <p>Somebody somewhere solved your problem. It might be in a very different industry. Problem solving projects help you to identify the areas outside your industry that have been facing similar challenges and offer some transferable solutions. Combining your specific expertise with out of domain knowledge acts as a checklist for innovation, a 360 in the solution space. Take &apos;color retention&apos; as a problem in hair care. The CPC map shows solutions in food, textiles, paint and many other fields.</p>
                </article>
                <article>
                    <h1>Innovation in your product or process</h1>
                    <div className="img-container"><img src='/images/projects2.png?v=2' alt='Clients' /></div>
                    <p>Your product or process is performing a function to your client. There are several ways to perform that function. What new ways are possible today? What variations can we bring to perform new or improved functions? There is a set of technology trends that appear across industries. Where is your solution on that scale and how can we move it forward? Innovation studies bring higher performance, less harmful elements, more convenience or less cost. In England you see these &quot;wavy&quot; brick fences. Fewer bricks than a straight wall for similar strength, a curious innovation.</p>
                </article>
                <article>
                    <h1>Markets for your technology</h1>
                    <div className="img-container"><img src='/images/projects3.png?v=2' alt='Clients' /></div>
                    <p>Instead of a problem looking for a solution, here we study a solution looking for problems. We&apos;ve invented a new product or material, but who needs it? By analyzing its properties and functions, in an abstract way, Patent Inspiration can identify the domains that are coping with the challenge or which your technology is the solution. Patent inspiration furthermore identifies what is the company and person within that company awaiting your solution.</p>
                </article>
            </section>
        </>
    )
}